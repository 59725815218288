export default {
  ko: {
    modal: {
      move_document: {
        title: `파일 이동`,
        confirm_button: `파일 이동`,
        confirm_msg: `이동되었습니다.`,
        strong_box_home: `스트롱박스 홈`,
      },
      delete_document: {
        title: "파일 삭제",
        body_title: "파일을 삭제할까요?",
        msg: (file: number) =>
          `삭제하시면 업로드된 파일 ${file}개 복구가 불가능합니다.\n정말 삭제하시겠어요?`,
        msg_highlight: (file: number) => `파일 ${file}개 복구가 불가능`,
        confirm: "파일 삭제",
      },
      edit_nda: {
        title: `NDA 템플릿 수정`,
      },
      create_nda: {
        title: `NDA 템플릿 생성`,
        template_name: `템플릿 이름`,
        template_name_placeholder: `미입력시 “NDA 파일” 이름으로 생성됩니다.`,
        template_desc: `템플릿 설명`,
        template_desc_placeholder: `템플릿 설명을 입력해주세요.`,
        template_file: `NDA 파일`,
        require: `(필수)`,
        max_length: (length: number) => `최대 ${length}자까지 입력 가능합니다.`,
        select_file: `파일 선택`,
        select_file_placeholder: `PDF파일만 업로드 가능합니다.`,
        cancel: `취소`,
        create: `생성`,
      },
      remove_nda: {
        title: `NDA 템플릿 삭제`,
        body_title: `정말 삭제하시겠습니까?`,
        msg: `삭제시 복구가 불가능합니다.`,
        remove: `삭제`,
      },
      add_file: {
        title: "파일추가",
        sub_title:
          "연구파일 순서 변경 및 경로 재 선택을 위한 상세 파일추가 입니다.",
        add_details: "상세 파일추가",
        written: "작성일자 선택",
        written_guide: "파일의 연구 일자를 선택해주세요.",
        authentication: "시점 인증 날짜",
        authentication_guide:
          "시점인증 날짜는 파일추가한 시간으로 제공되며, 수정이 불가능 합니다. (시점 인증 마크가 부여됩니다.)",
        authentication_guide_highlight: "수정이 불가능",
      },
      download: {
        loading: "다운로드 중입니다...",
        done: "다운로드가 완료되었습니다",
        problem: "문제가 발생하였습니다.",
        title: "연구노트 다운로드(PDF내보내기)",
        preview: "연구노트 미리보기",
        sort: "세로정렬 / 가로정렬",
        direction: "페이지 방향",
        direction_guide: "방향 옵션을 선택해주세요.",
        pdf_setting: "PDF 설정",
        pdf_cryp: "PDF 수정 보안",
        cryp_opt: "암호화 / 암호화 해제",
        cryp_opt1: "암호화",
        cryp_opt2: "암호화 해제",
        cryp_guide: {
          p1: "보안이 강력한 암호화 PDF를 생성합니다. 제출 시 필요에 따라 해제가 필요할 수 있습니다.",
          p2: "다만, 암호화 해제 시 보안에 취약할 수 있습니다.",
          p3: "(암호화 선택 시 다운로드 시간이 연장됩니다.)",
        },
        pdf_order: "PDF 연구파일 정렬",
        order_opt: {
          p1: "시점인증 최신",
          p2: "시점인증 과거",
          p3: "작성일자 최신",
          p4: "작성일자 과거",
          p5: "폴더 선택",
        },
        order_guide: {
          p1: "선택하신 연구파일의 정렬을 해당 옵션을 통해 변경 가능합니다.",
          p2: "폴더 선택 :",
          p3: "연구노트의 홈에서 정렬된 연구폴더는",
          p4: "업데이트 최신순, 연구파일은 시점인증 오래된순",
          p5: "으로 정렬됩니다.",
        },
        pdf_thumb: "표지 포함하기",
        thumb_guide:
          "PDF 생성 시 위와 같은 표지를 포함한 완전한 연구노트로 내보내기 됩니다.",
        pdf_cert: "인증서 포함하기",
        cert_guide:
          "인증마크를 포함한 PDF를 생성합니다. 증빙을 위한 연구노트는 인증서가 반드시 포함되어야 합니다.",
        pdf_comment: "코멘트 포함하기",
        comment_guide:
          "연구노트 작성 중에 멤버들이 남긴 코멘트 내용을 모두 포함한 연구노트를 생성합니다.",
        row: "가로",
        column: "세로",
        cancel: "취소",
        confirm: "연구노트 다운로드",
      },
      download_limitation: {
        title: "다운로드 제한",
        sub_title: "진행 중인 다운로드가 끝나면 재시도 해주세요.",
        confirm: "확인",
      },
      download_history: {
        title: "최근 연구노트 다운로드 내역",
        sub_title: "최근 10개의 다운로드 기록을 제공합니다.",
        empty_history: "다운로드 내역이 없습니다.",
        pending: "변환 중",
        success: "PDF 변환 완료",
        fail: "변환 실패",
        no_status: "상태가 없습니다.",
        confirm: "확인",
      },
      git_repository: {
        title: "연동하기",
        sub_title: "Git Repository 검색",
        guid_msg: {
          p1: "선택한 Repository의",
          p2: "Commit history, Comment, Issue",
          p3: "가 매일 밤 12시에 연구파일로 업데이트 됩니다.",
        },
        cancel: "취소",
        confirm: "서비스 연동",
        notice: "안내",
      },
      linkage_guide: {
        title: "서비스 연동 사용 설명서",
        sub_title: "Github Repository 연동",
      },
      new_project_info: {
        note_info: "연구노트 정보",
        manager: "과제 책임자",
        number: "과제 번호",
        period: "과제 기간",
        members: "연구노트 멤버",
        created: "생성 날짜",
        last_update: "파일 업데이트",
        del_permission: "연구노트 내 폴더 / 파일 삭제 권한",
        del_permission_desc:
          "연구노트 소유권한 외 다른 멤버가 연구노트 파일 삭제 가능합니다.",
        write_owner: "작성권한 / 소유권한",
        download_permission: "연구노트 다운로드 권한",
        download_permission_desc:
          "연구노트 소유권한 외 다른 멤버은 연구노트 다운로드가 불가능합니다.",
        close: "닫기",
        note_owner_permission: "연구노트 소유권한",
        note_owner_permission_desc: "노트 및 파일 관리, 노트 다운로드",
        file_del: "내 폴더 / 파일 삭제",
        file_download: "다운로드",
        option_description: (opt: string, lock: boolean) =>
          ` 연구노트 소유권한 외 다른 멤버의 연구노트 ${opt}가 ${
            lock ? "불가능" : "가능"
          }합니다.`,
      },
      member_manage: {
        members: "멤버 관리",
        share_link: "링크 공유",
        rw_permission: "연구 파일 추가, 노트 내 파일 이동",
        add_file: "연구 파일 추가",
        add_file_desc:
          "파일 삭제 및 연구노트 다운로드는 소유권한에게 권한을 요청하세요.",
        read_project: "연구노트 열람",
        read_project_desc:
          "연구노트 다운로드는 소유권한에게 권한을 요청하세요.",
        copy: "복사",
        invite_desc: "이메일과 이름으로 검색 가능합니다.",
        researchers: "연구노트 멤버",
        other_researchers: "외부 멤버",
        team_researchers: "팀 멤버",
        researcher_option: "멤버 권한 옵션",
        save: "저장",
        add_other_researchers: "외부 멤버 추가",
        all_invited: "워크스페이스 멤버가 전부 초대되었습니다.",
      },
      pdf_export: {
        title: "PDF 내보내기",
        description:
          "다운로드 시 팝업 허용이 되어 있지 않으면 다운로드된 파일을 확인할 수 없습니다.",
        orientation: "페이지 방향",
        horizontal: "가로정렬",
        vertical: "세로정렬",
        option: {
          option: "PDF 옵션",
          cover: "표지 포함하기",
          certification: "인증서 포함하기",
          comment: "코멘트 포함하기",
          change_order: "순서 변경하기",
          order_upload_recent: "업로드 최신 순",
          order_upload_asc: "업로드 오래된 순",
          order_written_recent: "작성일자 최신 순",
          order_written_asc: "작성일자 오래된 순",
          order_custom: "선택한 순서",
        },
        premium_feature_1:
          "PDF 옵션 선택은 프리미엄 유저 이상 이용 가능한 서비스 입니다.",
        premium_feature_2:
          "PDF 가로 정렬/옵션 기능은 프리미엄 사용자에게만 제공하는 기능입니다.",
      },
      researcher_list: {
        title: "연구노트 멤버 목록",
      },
      button: {
        export: "내보내기",
        add_note: "연구파일 추가",
        google_drive: "구글 드라이브 파일 추가",
        cancel: "취소",
        delete: "삭제",
        unlock: "해제",
        save: "저장",
        next: "다음",
        prev: "이전",
        complete: "완료",
        create: "만들기",
        read_only: "읽기권한",
        read_and_write: "작성권한",
        copy_link: "링크 복사",
        delete_link: "링크 삭제",
        change_location: "저장 위치 변경",
        upload: "파일 추가",
        confirm: "확인",
        close: "닫기",
        edit_save: "수정 사항 저장",
        add: "추가",
        create_note: "연구노트 생성",
        edit: "수정",
        send_email: "이메일 전송",
      },
      download_introduction: {
        title: "구노 소개서 다운로드",
        sub: "소중한 개인정보는 자료 발송 등 제한된 목적으로만 사용됩니다.",
      },
      delete_note: {
        title: "노트를 삭제할까요?",
        sub: "삭제하시면 복구가 불가능 합니다. 정말 삭제하시겠어요?",
      },
      delete_folder: {
        title: "폴더를 삭제할까요?",
        sub: "삭제하시면 복구가 불가능 합니다. 정말 삭제하시겠어요?",
      },
      delete_github: {
        title: "연동 서비스 해제",
        sub: "구노 폴더와 연동을 해제하시겠어요?\n해제하시면 자동 인증을 받을 수 없습니다.",
      },
      edit_hash_tag: {
        header: "태그 편집",
        tag: "태그",
        title: "태그를 입력해주세요.",
        subtitle:
          "태그 입력 후 엔터, 스페이스 바를 입력하여 수정할 태그를 등록한 뒤 저장을 해주세요.",
        sub: "(최대 30개)",
        recent: "최근 등록한 태그",
      },
      view_hash_tag: {
        header: "태그 확인",
        tags: "태그",
        empty_hash_tag: "등록된 태그가 없습니다.",
      },
      github_linkage_list: {
        title: "연동 목록",
        info: "연구노트 소유권한은 다른 멤버의 깃헙 연동 목록을 해제할 수 있습니다.",
        info_highlight: "다른 멤버의 깃헙 연동 목록",
        sub_title: "전체 연동 목록",
        empty_list: "연동된 Repository가 없습니다.",
        home: "연구노트 홈",
        add_repository: "연동하기",
        disconnect: "연동 해제",
        close: "닫기",
      },
      github_link: {
        title1: "연동할 Github Repository를 선택하세요.",
        title2: "연동할 구노 폴더를 선택하세요.",
        step: "/2 단계",
      },
      update_proj_admin: {
        header: "연구노트 소유권 변경",
        description: "워크스페이스 멤버에게만 소유권을 변경할 수 있습니다.",
        loading: "잠시만 기다려주세요...",
        not_selected: "변경할 멤버를 선택해주세요.",
        alert_title: "소유권한 변경 확인",
        alert_body:
          "정말 소유권한를 변경하시겠습니까?\n변경 된 후에는 되돌릴 수 없습니다.",
        alert_btn: "변경하기",
        alert_success: "성공적으로 반영되었습니다.",
        alert_failed: "소유권한 변경에 실패하였습니다. 다시 시도해주세요.",
        new_owner: "신규 소유권한",
        guide: "소유권 변경 가이드",
        desc1: "워크스페이스 멤버 대상으로 변경 가능합니다.",
        desc2:
          "소유권 이전 후 기존 소유주는 읽기 전용 권한으로 변경되며, 추가 및 편집이 불가능 합니다.",
        desc3: {
          p1: "해당 연구노트에",
          p2: "추가 및 편집이 필요 시 새로운 소유주에게 권한을 요청",
          p3: "하세요.",
        },
        change: "소유권한 변경",
        cancel: "취소",
      },
      new_note: {
        title: "새 파일 만들기",
        sub: "jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx 형식을 지원합니다.",
      },
      new_project: {
        title: "새 폴더 만들기",
        sub: "폴더 이름을 100자 이내로 입력해주세요.",
      },
      new_folder: {
        title: "새로운 폴더 생성",
        sub: "폴더명",
        placeholder: "폴더명을 입력해주세요.",
        condition: "최대 50자까지 입력 가능합니다.",
        condition_err: "폴더 명은 최대 최대 50글자까지 입력 가능합니다.",
        confirm: "폴더 생성",
      },
      edit_project: {
        title: "폴더 이름 변경",
        sub: "폴더 이름을 100자 이내로 입력해주세요.",
      },
      edit_folder: {
        title: "폴더명 수정",
        sub: "폴더명",
        condition: "최대 50자까지 입력 가능합니다.",
        condition_err: "폴더 명은 최대 최대 50글자까지 입력 가능합니다.",
        confirm: "수정",
      },
      note_detail: {
        write: "작성",
        title: "노트 정보",
        tag: "태그 편집",
        writer: "작성자",
        writer_email: "작성자 이메일",
        written_date: "작성 날짜",
        hash_value: "해쉬 값",
        block_num: "블록넘버",
        block_auth: "블록 인증 진행 중",
        auth_time: "시점 인증 시간",
        pages: "전체 페이지 수",
        comments: "전체 코멘트 개수",
        folder: "저장 폴더",
        size: "크기",
        extension: "파일 확장자",
        file_name: "파일명",
      },
      research_info: {
        header: "과제 정보 변경",
        cancel: "취소하기",
        save: "저장하기",
        confirm: "변경 사항이 저장되었습니다.",
        body: {
          info1: "입력하신 정보는 ",
          info2: " PDF 내보내기",
          info3: "에서 확인하실 수 있어요.",
          name_title: "과제 이름",
          name_owner: "과제 책임자",
          name_numb: "과제 번호",
          name_period: "과제 기간",
          start: "시작일",
          end: "종료일",
          place_holder: {
            name: "과제 이름을 100자 이내로 작성하세요.",
            owner: "과제 책임자 이름을 20자 이내로 작성하세요.",
            numb: "과제 번호를 20자 이내로 작성하세요",
            date: "클릭해서 날짜를 선택하세요.",
          },
        },
      },
      project_info: {
        header: "폴더 정보",
        admin: "소유권한",
        user: "사용자",
        count_1: "명",
        count: "명",
        create_time: "생성 날짜",
        last_upload_time: "마지막 업로드 날짜",
        save: "저장하기",
      },
      folder_setting: {
        header: "폴더 설정",
        save: "저장하기",
        cancel: "취소",
        linked_services_with_folder: "폴더와 연동된 서비스",
        prevent_file_download_without_owner:
          "노트 소유권한 외 파일 다운로드 방지",
        prevent_file_deletion_without_owner: "노트 소유권한 외 파일 삭제 방지",
        manage_delete_and_download: "삭제 및 다운로드 관리",
        folder_name: "폴더 이름",
        release: "해제 하기",
        locked: "작동 중",
        user_count: "사용자",
        user_count_postfix: "명",
        created_at: "생성 날짜",
        updated_at: "마지막 업데이트 된 날짜",
        no_linked_services_with_folder: "폴더와 연동 중인 서비스가 없습니다.",
        goto_linked_service_page: "연동 페이지로 이동하기",
        close_warning:
          "변경 내용이 저장되지 않았습니다. 나가시려면 X 버튼을 누르세요.",
        available_service_github_desc:
          "매일 밤 12시에 연동한 repository의 commit history, comment, issue가 올라갑니다.",
      },
      sign: {
        title: "전자서명",
        sub1: "연구파일의 시점인증과 연구노트 다운로드를 위해 작성자의 전자 서명을 추가해야 합니다.",
        refresh_sub1: "보안 정보가 업데이트 되었습니다.",
        sub2: "인증 노트 내려받기 시 확인할 수 있어요!",
        refresh_sub2: "전자서명 정보를 다시 기입해주세요.",
        once: "(*최초1회)",
        file_size_error: "파일 크기가 제한 용량인 1MB를 초과합니다.",
        draw: "그리기",
        add_file: "파일 업로드",
        file_tab: {
          title: "서명 사진을 업로드 해주세요.",
          desc_01: "파일 용량 한도: 1MB",
          desc_02: "업로드 가능한 확장자 : png, jpg, jpeg, bmp, heif, heic",
          upload_btn: "사진 업로드",
          delete_btn: "파일 삭제",
        },
      },

      add_note: {
        fail: "실패",
        more: "더 보기",
        complete: "완료",
        check_save: "저장 위치를 다시 확인해주세요.",
        check_file: "1개 이상의 파일을 업로드해주세요.",
        check_readonly:
          "읽기만 가능한 사용자는 연구노트 업로드 권한이 없습니다.",
        header: {
          title1: "저장할 폴더를 선택해주세요.",
          title2: "개의 파일을 업로드 할까요?",
        },
        footer: {
          title: "저장 폴더",
          no_folder: "폴더 없음",
        },
        uploading: "개의 파일 업로드 중",
        upload_done: "개의 파일 업로드",
        gfile_option_selected: "선택한 파일 보기",
        gfile_option_default: "전체 드라이브 파일 보기",
        gfile_logout: "구글 드라이브 로그아웃",
        gfile_count: "개 선택",
        alert_failed_get_files: "파일을 가져오는데 실패하였습니다.",
        alert_failed_google: "구글 인증 정보를 불러오는데 실패하였습니다.",
        alert_no_files: "저장된 파일이 없습니다.",
      },
      gdt: {
        header: {
          title1: "저장할 폴더를 선택해주세요.",
          title2: "구노로 가져올 파일을 선택하세요.",
        },
      },
      survey: {
        title: "유료 플랜 14일 무료 체험하기",
        description:
          "남겨주신 연락처로 담당 컨설턴트가 1일 이내로 연락을 드립니다.",
        placeholder_email: "사용하실 GOONO 계정 이메일을 입력해주세요.",
      },
      move_note: {
        title: "이동할 폴더를 선택해주세요.",
        btn: "폴더로 이동",
        alert_same_folder: "똑같은 폴더를 선택하셨습니다.",
        success_msg_1: "해당 노트가",
        success_msg_2: "로 이동하였습니다.",
      },
      move_file: {
        title: "파일 이동",
        sub_title: (length?: number) =>
          `${length} 개의 연구파일 이동을 위해 경로를 선택해주세요.`,
        confirm: "파일 이동",
        placeholder: "이동 경로",
        home: "연구노트 홈",
        project_list_title: "이동 가능 연구노트",
        project_list_helpertext: "연구파일을 이동할 연구노트를 선택해주세요.",
        project_list_placeholder: "연구노트 이름으로 검색 해주세요.",
        folder_list_title: "이동 가능 폴더",
        folder_list_helpertext: "연구파일을 이동할 연구폴더를 선택해주세요.",
        new_folder: "폴더생성",
      },
      privacy: {
        title: "개인정보 취급 방침",
        sub_title_1: "동의를 거부할 권리 및 동의 거부에 따른 불이익",
        sub_title_2:
          "이용자는 개인정보 수집 및 이용에 거부할 권리가 있습니다. 다만 동의하지 않는 경우, 서비스 신청 및 사용에 제한이 있을 수 있습니다.",
        info: {
          purpose: "수집 및 이용 목적",
          purpose_content:
            "무료 체험 서비스 제공 및 운영, 무료 체험 서비스 이용 고객 관리",
          collect_list: "수집 항목 (필수)",
          collect_list_content:
            "회사명, 성명(성과 이름), 이메일, 휴대전화 번호",
          retention_period: "보유 기간",
          retention_period_content:
            "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
        },
      },
      password: {
        title: "비밀번호 변경",
        desc_01: "180일 동안 비밀번호를 변경하지 않으셨어요.",
        desc_01_highligth: "180일",
        dese_02: "새로운 비밀번호로 계정을 안전하게 관리해주세요.",
        form: {
          current_placeholder: "현재 비밀번호",
          current_helpertext: "현재 사용 중인 비밀번호를 입력해주세요.",
          new_placeholder: "새 비밀번호",
          new_helpertext: "문자,숫자,특수문자 조합 8자리 이상",
          confirm_new_placeholder: "새 비밀번호 확인",
          confirm_new_helpertext: "새 비밀번호를 한번 더 입력해주세요.",
          is_not_match_as_entered_new_password: "비밀번호가 일치하지 않습니다",
          same_password_as_current_password:
            "기존 비밀번호와 동일한 비밀번호입니다.",
        },
        button: {
          later: "다음에 변경하기",
          confirm: "변경하기",
        },
      },
      sample: {
        desc: "샘플 연구노트를 위한 샘플 화면입니다.",
      },
      help_center: {
        title: "도움말",
        desc: "궁금하신 점이나 문의 사항이 있으신가요?",
        story: "구노 스토리가 궁금해요",
        news: "구노의 모든 소식을 확인할 수 있어요",
        faq: "자주 묻는 질문들을 모아놨어요",
      },
      workspace_leave: {
        title: "워크스페이스 나가기",
        msg: "정말 워크스페이스를 나가시겠습니까?",
        confirm: "나가기",
      },
      workspace_remove: {
        title: "워크스페이스 삭제",
        msg: "워크스페이스 삭제를 원하신다면 아래 입력칸에 현재 워크스페이스 이름을 동일하게 작성하세요.",
        confirm: "삭제",
      },
      member_remove: {
        title: "멤버 삭제",
        body_title: "선택한 멤버를 삭제할까요?",
        body_msg:
          "기존 멤버의 소유권은 워크스페이스 소유자에게 모두 이전됩니다.",
        confirm: "삭제",
      },
      transfer_owner: {
        title: "소유권 이전",
        body_title: "소유권을 이전할까요?",
        body_msg: "소유권 이전 시 귀하는 연구원 권한으로 자동 변경됩니다.",
        confirm: "확인",
      },
      update_onboarding: {
        title: "업데이트 내용 안내",
        tab_01: {
          title: "워크스페이스 도입",
          desc: "라이센스가 워크스페이스로 변경되면서 기존 라이센스의 기관명으로 워크스페이스명이 자동 생성되었습니다.",
        },
        tab_02: {
          title: "워크스페이스 설정",
          desc: `소유자와 관리자는 워크스페이스 설정에서 이름을 수정할 수 있어요! 
          이미지를 등록하지 않을 시 워크스페이스명의 앞글자가 프로필로 자동 생성되며, 
          직접 이미지를 업로드하여 변경할 수 있습니다.`,
        },
        tab_03: {
          badge: "업데이트 예정",
          title: "데이터룸",
          desc: `데이터룸은 내부 문서의 보안 관리를 할 수 있고, 
          공유링크를 통해 접속한 방문자의 인사이트를 제공해요! 
          또한 NDA를 통해 비밀유지협약 등 다양한 공유 옵션을 설정하여 
          외부 사용자의 파일 접근에 대한 보안을 한층 더 강화할 수 있습니다.`,
        },
        more: "자세히 보기",
        next: "다음",
        confirm: "확인",
      },
      file_name: {
        title: "파일명 변경",
        placeholder: "변경할 파일명을 입력하세요.",
      },
      strong_box_delete: {
        title: "Strong Box 삭제",
        body_title: (name: string) => `${name}을(를) 삭제하시겠습니까?`,
        msg: (file: number) =>
          `삭제하시면 업로드된 파일 ${file}개 복구가 불가능합니다.\n정말 삭제하시겠어요?`,
        msg_highlight: (file: number) => `파일 ${file}개 복구가 불가능`,
        confirm: "삭제",
      },
      update_strong_box_admin: {
        title: "Strong Box 소유권 변경",
        guide: "소유권 변경 가이드",
        msg_01: "워크스페이스 멤버 대상으로 변경 가능합니다.",
        msg_02: "소유권 이전 후 기존 소유자는 작성권한으로 변경됩니다.",
        new_admin: "신규 소유권한",
        confirm: "소유권 이전",
      },
      create_share_link_complete: {
        title: "공유링크 생성",
        sub_title: "공유 링크 생성이 완료되었습니다.",
        description: "링크 복사 또는 이메일로 공유하세요.",
        copy: "클립보드에 복사",
        reply: "메일 전송",
        share_link_name: "링크 이름",
        expires_at: "유효 기간",
        file_name: "파일",
        share_link_settings: "공유 옵션",
      },
      select_nda_template: {
        title: "NDA 템플릿 선택",
        sub_title: "NDA 템플릿 파일을 선택해주세요.",
        creator: "생성자",
        confirm: "선택",
      },
      create_document_share_link: {
        title: "공유링크 생성",
        name: "링크이름",
        expires_at: "유효 기간",
        link_settings: "공유 옵션",
        agreement_nda: "NDA(기밀 유지 서약) 동의",
        expires_at_description: "공유 링크 만료 일자를 선택하세요.",
        expires_at_placeholder: "유효기간",
        link_settings_email: {
          label: "이메일 수집하기",
          description: "링크를 보려면 이메일을 입력해야 합니다.",
        },
        link_settings_download: {
          label: "다운로드 허용하기",
          description: "링크 안 파일을 다운로드 할 수 있습니다.",
        },
        agreement_nda_description: "링크를 보려면 NDA에 서명해야 합니다.",
        select_nda_title: "NDA 파일",
        select_nda_title_child: "(필수)",
        select_nda_placeholder: "템플릿 파일을 선택해주세요.",
        select_nda_button: "NDA템플릿 선택",
        confirm: "링크 생성",
      },
      delete_document_share_link: {
        title: "공유링크 삭제",
        sub_title: "공유링크를 삭제하시겠습니까?",
        description: "복구가 불가능합니다.",
        confirm: "삭제",
      },
      free_plan_form: {
        title: "체험판 신청",
        start: "체험판 시작",
        condition: "최대 50자까지 입력 가능합니다.",
        referrer_label: "추천인",
        referrer_placeholder: "추천인 이메일을 적어주세요.",
      },
      data_room_price: {
        title: "Strong box 가격 안내",
      },
    },
  },
  en: {
    modal: {
      move_document: {
        title: `Move file`,
        confirm_button: `Move file`,
        confirm_msg: `Moved.`,
        strong_box_home: `Strong Box home`,
      },
      edit_nda: {
        title: `Edit Template`,
      },
      create_nda: {
        title: `New Template`,
        template_name: `Template name`,
        template_name_placeholder: `Untitled template`,
        template_desc: `Description`,
        template_desc_placeholder: `Enter a description here.`,
        template_file: `NDA File`,
        require: `(Required)`,
        max_length: (length: number) => `Maximum ${length} characters`,
        select_file: `Select`,
        select_file_placeholder: `PDF only`,
        cancel: `Cancel`,
        create: `Create`,
      },
      remove_nda: {
        title: `Delete Template`,
        body_title: `Are you sure?`,
        msg: `Deleting the template is permanent and can't be undone.`,
        remove: `Delete`,
      },
      add_file: {
        title: "Add research files",
        sub_title:
          "Detailed Add for reordering research files and re-selecting paths.",
        add_details: "Detailed add",
        written: "Written on",
        written_guide:
          "Please select the date you recorded or documented after the research",
        authentication: "Authentication",
        authentication_guide:
          "Authentication is provided as add file time and cannot be modified. (A authenticate mark is given.)",
        authentication_guide_highlight: "cannot be modified",
      },
      download: {
        loading: "loading...",
        done: "Download is complete.",
        problem: "A problem has occurred.",
        title: "Download note(Export as PDF)",
        preview: "Preview a cover",
        sort: "Vertical / Horizontal",
        direction: "Orientation",
        direction_guide: "Select the type of orientation.",
        pdf_setting: "PDF setting",
        pdf_cryp: "PDF Cryption",
        cryp_opt: "Crypted / Decrypted",
        cryp_opt1: "Crypted",
        cryp_opt2: "Decrypted",
        cryp_guide: {
          p1: "You can create securely crypted PDF file.",
          p2: "But it may need to be decrypted.Decryption can cause file security to be weak.",
          p3: "(Download time will be extended when encryption is selected.)",
        },
        pdf_order: "Sort",
        order_opt: {
          p1: "Recently Authenticated",
          p2: "First Authenticated",
          p3: "Recently written",
          p4: "First written",
          p5: "Folder Selection Order",
        },
        order_guide: {
          p1: "Folder Selection Order",
          p2: "Select Folder:",
          p3: "Research folders that are sorted in the ",
          p4: "latest update order, and Research files are sorted in the first authentication ",
          p5: "order.",
        },
        pdf_thumb: "Include cover",
        thumb_guide:
          "Research note including cover will be exported to PDF file.",
        pdf_cert: "Include certification",
        cert_guide:
          "Authentication mark will be included to PDF file. Authentication of research note is a must for legal proof.",
        pdf_comment: "Include comment",
        comment_guide: "All comments will be included to PDF file.",
        row: "Horizontal",
        column: "Vertical",
        cancel: "Cancel",
        confirm: "Download",
      },
      download_limitation: {
        title: "Download limitation",
        sub_title: "Please try again after the download is complete.",
        confirm: "Confirm",
      },
      download_history: {
        title: "Recent note download history",
        sub_title: "We provide the download history of the latest 10 records.",
        empty_history: "There is no history.",
        pending: "Converting",
        success: "PDF conversion complete",
        fail: "Failed",
        no_status: "No status",
        confirm: "Confirm",
      },
      git_repository: {
        title: "Git repository",
        sub_title: "Search for repository",
        guid_msg: {
          p1: "Commit history, comments, and issues for the",
          p2: "selected repository",
          p3: "are added to the research file at 12:00 every night.",
        },
        cancel: "Cancel",
        confirm: "Integration",
        notice: "Notice",
      },
      linkage_guide: {
        title: "Service integration manual",
        sub_title: "Github repository Integration",
      },
      new_project_info: {
        note_info: "Research note information",
        manager: "Manager",
        number: "No.",
        period: "Period",
        members: "Members",
        created: "Created",
        last_update: "Updated",
        del_permission: "Permission to delete files",
        del_permission_desc:
          "Research notes cannot be Delete file’s by other/ team member’s than the owner of the research note.",
        write_owner: "Writing only / Owner",
        download_permission: "Permission to download research note",
        download_permission_desc:
          "Research notes cannot be download by other/ team member’s than the owner of the research note.",
        close: "Close",
        note_owner_permission: "Permissions",
        note_owner_permission_desc: "Note/File management, Note download",
        file_del: "delete research files",
        file_download: "download the research note",
        option_description: (opt: string, lock: boolean) =>
          `Researchers who are not the owner ${
            lock ? "cannot" : "can"
          } ${opt}.`,
      },
      member_manage: {
        members: "Members",
        share_link: "Share link",
        rw_permission: "Add research file, Move research file",
        add_file: "Add research file.",
        add_file_desc:
          "(You can request file deletion or note download to note owner.)",
        read_project: "View research note.",
        read_project_desc: "(You can request note download to note owner.)",
        copy: "Copy",
        invite_desc: "Search for team members by e-mail or name.",
        researchers: "Research members",
        other_researchers: "External member",
        team_researchers: "Team member",
        researcher_option: "Member permission",
        save: "Modify",
        add_other_researchers: "External members",
        all_invited: "All team members have been invited.",
      },
      pdf_export: {
        title: "Export PDF",
        description:
          "You can't see files if the pop-up is not allowed when downloading.",
        orientation: "Page Orientation",
        horizontal: "Horizontal Alignment",
        vertical: "Vertical Alignment",
        option: {
          option: "PDF Option",
          cover: "Include Cover Page",
          certification: "Include Certification",
          comment: "Include Comment",
          change_order: "Change Order",
          order_upload_recent: "Latest Uploaded",
          order_upload_asc: "First Uploaded",
          order_written_recent: "Latest Filed",
          order_written_asc: "First Filed",
          order_custom: "Selected Order",
        },
        premium_feature_1: "PDF option feature is available for premium user.",
        premium_feature_2:
          "PDF alignment/option feature is available for premium user.",
      },
      researcher_list: {
        title: "Research member list",
      },
      button: {
        export: "Export",
        add_note: "Add Files",
        google_drive: "Add Files from Google Drive",
        cancel: "Cancel",
        delete: "Delete",
        unlock: "Unlock",
        save: "Save",
        next: "Next",
        prev: "Prev",
        complete: "Done",
        create: "Create",
        read_only: "Read only",
        read_and_write: "Write only",
        copy_link: "Copy Link",
        delete_link: "Delete Link",
        change_location: "Change the Storage Location",
        upload: "Add File",
        confirm: "Confirm",
        close: "Close",
        edit_save: "Edit save",
        add: "Add",
        create_note: "Create",
        edit: "Edit",
        send_email: "Send",
      },
      download_introduction: {
        title: "Download Introduction to GOONO",
        sub: "Personal information is used only for limited purposes.",
      },
      delete_note: {
        title: "Do you want to delete the note?",
        sub: "If you delete it, it cannot be restored. Are you sure you want to delete it?",
      },
      delete_folder: {
        title: "Do you want to delete the folder?",
        sub: "If you delete it, it cannot be restored. Are you sure you want to delete it?",
      },
      delete_github: {
        title: "Unlock integration Service",
        sub: "Will you unlock the interlocking with the GOONO folder? \nIf you do, you will not receive automatic certification.",
      },
      edit_hash_tag: {
        header: "Edit Tag",
        title: "Enter the tags.",
        tag: "Tag",
        subtitle:
          "Please save changes after make hashtags. (You can break words with space-bar, or enter button)",
        sub: "(Max 30)",
        recent: "Recently tags",
      },
      view_hash_tag: {
        header: "View tags",
        tags: "Tag info",
        empty_hash_tag: "There are no added tags.",
      },
      github_linkage_list: {
        title: "Integration list",
        info: "The owner of the note can disconnect the other researcher's github linkage list",
        info_highlight: "other researcher's github linkage list",
        sub_title: "Full Integration list",
        empty_list: "There are no linked repositories.",
        home: "Research note home",
        add_repository: "Add repository",
        disconnect: "Disconnect",
        close: "Close",
      },
      github_link: {
        title1: "Select Github repository for integration.",
        title2: "Select GOONO folder for integration.",
        step: "/2 Step",
      },
      new_note: {
        title: "Create a New Note",
        sub: "jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx are available.",
      },
      new_project: {
        title: "Create a New Folder",
        sub: "You cannot write more than 100 letters.",
      },
      new_folder: {
        title: "New Folder",
        sub: "Folder name",
        placeholder: "Untitled folder",
        condition: "Maximum 50 characters",
        condition_err: "Maximum 50 characters",
        confirm: "Create",
      },
      edit_project: {
        title: "Change Folder Name",
        sub: "You cannot write more than 100 letters.",
      },
      edit_folder: {
        title: "Modify folder name",
        sub: "Folder name",
        condition: "Maximum 50 characters",
        condition_err: "Maximum 50 characters",
        confirm: "Modify",
      },
      note_detail: {
        write: "Write",
        title: "Note info",
        tag: "Edit tag",
        writer: "Created by",
        writer_email: "Writer email",
        written_date: "Created on",
        hash_value: "Hash",
        block_num: "Block number",
        block_auth: "Block Authentication in Progress",
        auth_time: "Authentication Time",
        pages: "Pages",
        comments: "Comments",
        folder: "Folder",
        size: "Size",
        extension: "Original file type",
        file_name: "File name",
      },
      research_info: {
        header: "Edit Research Project Info",
        confirm: "Your changes have been saved.",
        save: "Save",
        cancel: "Cancel",
        body: {
          info1: "The information you entered can be found in",
          info2: "the PDF export.",
          info3: "",
          name_title: "Research Title",
          name_owner: "Research Manager",
          name_numb: "Research Number",
          name_period: "Research Period",
          start: "Start",
          end: "End",
          place_holder: {
            name: "Please write a research title less than 100 characters.",
            owner: "Please write a manager name less than 20 characters.",
            numb: "Please write your research number less than 20 characters.",
            date: "Click to select a date.",
          },
        },
      },
      project_info: {
        header: "Folder Info",
        admin: "Owner",
        user: "User",
        count_1: "person",
        count: "people",
        create_time: "Created",
        last_upload_time: "Last Uploaded Date",
        save: "Save",
      },
      folder_setting: {
        header: "Folder Setting",
        save: "Save",
        cancel: "Cancel",
        linked_services_with_folder: "Linked Services with the Folder",
        prevent_file_download_without_owner:
          "Prevent Downloading Files Other than Folder Owners",
        prevent_file_deletion_without_owner:
          "Prevent Deleting Files Other than Folder Owners",
        manage_delete_and_download: "File Download & Deleteion Management",
        folder_name: "Folder Name",
        release: "Release",
        locked: "Locked",
        user_count: "Users Count",
        user_count_postfix: "",
        created_at: "Created on",
        updated_at: "Last Updated on",
        no_linked_services_with_folder:
          "There is no linked service with the folder.",
        goto_linked_service_page: "Go to Linked Service Page",
        close_warning:
          "Changes have not been saved. Press the X button to close the modal.",
        available_service_github_desc:
          "Every night at 12 p.m., the comment history, comment, and issue of the repository are uploaded.",
      },
      sign: {
        title: "Signature",
        sub1: "To authenticate the timestamp of the research file and download the research notes, the author's electronic signature must be added.",
        refresh_sub1: "Security information has been updated.",
        sub2: "You can check when downloading the certified note!",
        refresh_sub2: "Please re-enter your electronic signature.",
        once: "(*First time only)",
        file_size_error: "The file size exceeds 1MB.",
        draw: "Drawing",
        add_file: "Add image",
        file_tab: {
          title: "Please upload the signature image.",
          desc_01: "File capacity limit: 1MB",
          desc_02: "Uploadable extensions: png, jpg, jpeg, bmp, heif, heic",
          upload_btn: "Upload",
          delete_btn: "Delete",
        },
      },
      add_note: {
        fail: "Failed",
        more: "See More",
        complete: "Complete",
        check_save: "Please check the folder again.",
        check_file: "Please upload more than one file.",
        check_readonly: "Readonly users cannot upload notes.",
        header: {
          title1: "Please select a folder.",
          title2: "files upload?",
        },
        footer: {
          title: "Folder",
          no_folder: "No Folder",
        },
        uploading: " notes uploading",
        upload_done: " notes uploaded!",
        gfile_option_selected: "See Selected",
        gfile_option_default: "All Files",
        gfile_logout: "Google Drive Sign out",
        gfile_count: "files selected",
        alert_failed_get_files: "Failed to load files",
        alert_failed_google: "Failed to authenticate on GoogleDrive",
        alert_no_files: "It's an empty drive.",
      },
      gdt: {
        header: {
          title1: "Please select a folder.",
          title2: "Select a file to import into Goono.",
        },
      },
      update_proj_admin: {
        header: "Change note owner",
        description:
          "This ownership can be transferred to member who shares the same workspace.",
        loading: "Loading Users...",
        not_selected: "Please select user to transfer ownership.",
        alert_title: "Confirm to transfer Folder ownership.",
        alert_body: "Continue? You cannot revoke after transferred.",
        alert_btn: "Transfer",
        alert_success: "Successfully transffered",
        alert_failed: "Failed to transfer folder ownership. Please try again.",
        new_owner: "New owner",
        guide: "Owner change guide",
        desc1: "You can change an owner to other workspace member.",
        desc2: "The original owner cannot add or edit after the change.",
        desc3: {
          p1: "Make request",
          p2: "to new owner for ",
          p3: "any addition or edit. ",
        },
        change: "Change owner",
        cancel: "cancel",
      },
      survey: {
        title: "Request 14-Days Demo",
        description:
          "Our professional consultant will reply within 1 business day.",
        placeholder_email: "Please Enter your email.",
      },
      move_note: {
        title: "Please select folder to move notes.",
        btn: "Move",
        alert_same_folder: "Select different one.",
        success_msg_1: "Successfully transferred to ",
        success_msg_2: ".",
      },
      move_file: {
        title: "Move files",
        sub_title: (length?: number) =>
          `Please select a route for  ${length} to move.`,
        confirm: "Move",
        placeholder: "Moving route",
        home: "Research note home",
        project_list_title: "Moveable research note",
        project_list_helpertext:
          "Please select the research note to move the file.",
        project_list_placeholder:
          "Please search the name of the research note.",
        folder_list_title: "Moveable folders",
        folder_list_helpertext:
          "Please select the research folder to move the file.",
        new_folder: "New folder",
        move: "Move",
      },
      privacy: {
        title: "Privacy Policy",
        sub_title_1: "Right to refuse consent and consequences of refusal",
        sub_title_2:
          "You have the right to refuse the collection and use of personal information. However, if you don’t agree, there may be restrictions on service application and usage.",
        info: {
          purpose: "Purpose of collection and use",
          purpose_content:
            "Provision and operation of free trial service, management of customers using the free trial service",
          collect_list: "Collect List (Required)",
          collect_list_content:
            "Company, name (First and Last name), Email, phone number",
          retention_period: "Retention period",
          retention_period_content:
            "Last usage date or the retention period in accordance with the law after membership withdrawal is one year.",
        },
      },
      password: {
        title: "Change password",
        desc_01: "No password change in 180 days.",
        desc_01_highligth: "180 days.",
        dese_02: "Secure your account with a new password.",
        form: {
          current_placeholder: "Password",
          current_helpertext: "Enter the password you are currently using.",
          new_placeholder: "New password",
          new_helpertext:
            "At least 8 digits of letters, numbers, and special letters.",
          confirm_new_placeholder: "Confirm new password",
          confirm_new_helpertext: "Enter the new password again.",
          is_not_match_as_entered_new_password:
            "New password and confirm password must match.",
          same_password_as_current_password:
            "New password can't be the same as the current one.",
        },
        button: {
          later: "Change later",
          confirm: "Change",
        },
      },
      sample: {
        desc: "This is a sample view for sample research note.",
      },
      help_center: {
        title: "Help",
        desc: "All about GOONO",
        story: "Stories of GOONO",
        news: "News of GOONO",
        faq: "Frequently Asked Questions",
      },
      workspace_leave: {
        title: "Leave",
        msg: "Are you absolutely sure you want to leave the workspace?",
        confirm: "Leave",
      },
      workspace_remove: {
        title: "Delete",
        msg: "If you want to delete this workspace, please type the name of the workspace you wish to delete in the input field provided.",
        confirm: "Delete",
      },
      member_remove: {
        title: "Delete member",
        body_title: "Are you sure to delete the members?",
        body_msg:
          "The ownership of the current member has been transferred to the owner of the workspace.",
        confirm: "Delete",
      },
      transfer_owner: {
        title: "Transfer ownership",
        body_title: "Are you sure you want to transfer ownership?",
        body_msg:
          "When ownership is transferred, you will automatically be changed to a researcher.",
        confirm: "Confirm",
      },
      update_onboarding: {
        title: "Update Notification",
        tab_01: {
          title: "About Workspace",
          desc: `As the license has been changed to a workspace, the workspace name was automatically set to organization name in the existing license.`,
        },
        tab_02: {
          title: "Set up a workspace",
          desc: `Owners and administrators can now update workspace names in the settings. 
          If no profile picture is uploaded, the first letter of the name will be used as the default profile picture. 
          However, you can upload your own profile picture and change it anytime.`,
        },
        tab_03: {
          badge: "Upcoming",
          title: "Data Room",
          desc: `Data Room provides secure internal document management, 
          as well as visitor insights through shared links. 
          It offers options for NDA and enhanced security against external access.`,
        },
        more: "View more",
        next: "Next",
        confirm: "OK",
      },
      file_name: {
        title: "Rename File",
        placeholder: "Please enter a file name.",
      },
      strong_box_delete: {
        title: "Delete Strong Box",
        body_title: (name: string) => `Delete ${name}?`,
        msg: (file: number) =>
          `Are you sure? "${file}" file${
            file > 1 ? `s` : ``
          } will be deleted permanently and can't be undone.`,
        msg_highlight: (file: number) =>
          `"${file}" file${file > 1 ? `s` : ``} will be deleted permanently`,
        confirm: "Delete",
      },
      update_strong_box_admin: {
        title: "Transfer ownership",
        guide: "How it works",
        msg_01: "Ownership can only be transferred to a workspace member.",
        msg_02:
          "The previous owner will have read-only access after the transfer.",
        new_admin: "New owner",
        confirm: "Transfer ownership",
      },
      create_share_link_complete: {
        title: "Create Custom Link",
        sub_title: "Link created successfully.",
        description: "Copy the link or share via email.",
        copy: "Copy",
        reply: "Send email",
        share_link_name: "Link name",
        expires_at: "Expires at",
        file_name: "File",
        share_link_settings: "Link settings",
      },
      select_nda_template: {
        title: "Select NDA Template",
        sub_title: "Select a template file",
        creator: "Created by",
        confirm: "Select",
      },
      create_document_share_link: {
        title: "Create Custom Link",
        name: "Name",
        expires_at: "Valid date",
        link_settings: "Link settings",
        agreement_nda: "Require NDA agreement",
        expires_at_description: "Select an expiry date",
        expires_at_placeholder: "Expires at",
        link_settings_email: {
          label: "Require email",
          description: "Viewers must submit email address to view the link",
        },
        link_settings_download: {
          label: "Allow downloading",
          description: "Viewers can download the fiile",
        },
        agreement_nda_description: "Viewers must sign NDA to view the link",
        select_nda_title: "NDA file",
        select_nda_title_child: "(required)",
        select_nda_placeholder: "Select a template file",
        select_nda_button: "Select",
        confirm: "Create link",
      },
      delete_document_share_link: {
        title: "Delete Link",
        sub_title: "Delete this link?",
        description: "Deleting can't be undone.",
        confirm: "Delete",
      },
      free_plan_form: {
        title: "Try for free",
        start: "Start",
        condition: "Maximum 50 characters",
        referrer_label: "Referrer",
        referrer_placeholder: "Please enter the referrer's email address.",
      },
      delete_document: {
        title: "Delete File",
        body_title: "Are you sure to delete file?",
        msg: (file: number) =>
          `If you delete it, you cannot restore ${file} created files.\nAre you sure to delete?`,
        msg_highlight: (file: number) =>
          `cannot restore ${file} created files.`,
        confirm: "Delete",
      },
      data_room_price: {
        title: "Pricing",
      },
    },
  },
};
